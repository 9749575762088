var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"supplier-invoice","bladeName":"supplier-invoices","bladesData":_vm.bladesData,"canAdd":false,"canSearchLocal":"","getParams":function (item) { return { includeDetails: false, supplierIDs: item.data == null ? null : item.data.supplierID }},"headers":[
        { text: 'INV#', value: 'invoiceNumber', subtitle: 2, prefix: '#' }, 
        { text: 'Issued On', value: 'issuedOn', textFilter: 'toShortDate' },
        { text: 'Due Date', value: 'dueOn', textFilter: 'toShortDate', subtitle: 1, prefix: 'Due: ' },
        { text: 'Supplier', value: 'seller.companyName', title: 1 },
        { text: 'Amount', value: 'amountTotal', textFilter: 'toCurrency' },
        { text: 'Status', value: 'isPaid', display: true, subtitle: 2 }],"hideActions":"","navigation":"supplier-invoices","searchProps":['invoiceNumber','purchaseOrderNumber','customerOrderNumber'],"useServerPagination":"","two-line":"","title":"Supplier Invoices"},scopedSlots:_vm._u([{key:"settings",fn:function(){return [(_vm.$canView('invoicing-settings'))?_c('BT-Entity',{attrs:{"ignoreID":"","inline":"","navigation":"invoicing-settings","single":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var save = ref.save;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Email Invoice Notifications To")])],1),_c('v-list-item-action',[_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('BT-Tags',{on:{"change":save},model:{value:(item.emailAddressesAsCustomer),callback:function ($$v) {_vm.$set(item, "emailAddressesAsCustomer", $$v)},expression:"item.emailAddressesAsCustomer"}})]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"small":"","icon":"","title":item.emailAddressesAsCustomer}},[_c('v-icon',{class:item.emailAddressesAsCustomer == null || item.emailAddressesAsCustomer.length == 0 ? '' : 'success--text',attrs:{"small":""}},[_vm._v("mdi-email")])],1)],1)],1)],1)]}}],null,false,2011692311)}):_vm._e()]},proxy:true},{key:"isPaid",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPaid ? 'Paid' : 'Unpaid')+" ")]}},{key:"listItem",fn:function(ref){
        var item = ref.item;
return [_c('BT-List-Item-Avatar',{attrs:{"rounded":""},model:{value:(item.sellerID),callback:function ($$v) {_vm.$set(item, "sellerID", $$v)},expression:"item.sellerID"}}),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.seller.companyName))]),_c('v-list-item-subtitle',[_vm._v("#"+_vm._s(item.invoiceNumber))]),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm._f("toShortDate")(item.issuedOn)))])],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.amountTotal))+" ")]),(item.paidOn == null)?_c('span',{staticClass:"error--text"},[_vm._v("Unpaid")]):_c('span',{staticClass:"success--text"},[_vm._v("Paid")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }