<template>
    <BT-Blade-Items
        addBladeName="supplier-invoice"
        bladeName="supplier-invoices"
        :bladesData="bladesData"
        :canAdd="false"
        canSearchLocal
        :getParams="item => { return { includeDetails: false, supplierIDs: item.data == null ? null : item.data.supplierID }}"
        :headers="[
            { text: 'INV#', value: 'invoiceNumber', subtitle: 2, prefix: '#' }, 
            { text: 'Issued On', value: 'issuedOn', textFilter: 'toShortDate' },
            { text: 'Due Date', value: 'dueOn', textFilter: 'toShortDate', subtitle: 1, prefix: 'Due: ' },
            { text: 'Supplier', value: 'seller.companyName', title: 1 },
            { text: 'Amount', value: 'amountTotal', textFilter: 'toCurrency' },
            { text: 'Status', value: 'isPaid', display: true, subtitle: 2 }]"
        hideActions
        navigation="supplier-invoices"
        :searchProps="['invoiceNumber','purchaseOrderNumber','customerOrderNumber']"
        useServerPagination
        two-line
        title="Supplier Invoices">
            <template v-slot:settings>
                <BT-Entity
                    v-if="$canView('invoicing-settings')"
                    ignoreID
                    inline
                    navigation="invoicing-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Email Invoice Notifications To</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-edit-dialog>
                                    <v-btn small icon :title="item.emailAddressesAsCustomer">
                                        <v-icon small :class="item.emailAddressesAsCustomer == null || item.emailAddressesAsCustomer.length == 0 ? '' : 'success--text'">mdi-email</v-icon>
                                    </v-btn>
                                    <template v-slot:input>
                                        <BT-Tags 
                                            v-model="item.emailAddressesAsCustomer"
                                            @change="save" />
                                    </template>
                                </v-edit-dialog>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </BT-Entity>
            </template>
            <template v-slot:isPaid="{ item }">
                {{ item.isPaid ? 'Paid' : 'Unpaid' }}
            </template>
            <template v-slot:listItem="{ item }">
                <BT-List-Item-Avatar v-model="item.sellerID" rounded />
                <v-list-item-content>
                    <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                    <v-list-item-subtitle>#{{ item.invoiceNumber }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-caption">{{ item.issuedOn | toShortDate }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-list-item-action-text>
                        {{ item.amountTotal | toCurrency }}
                    </v-list-item-action-text>
                    <span v-if="item.paidOn == null" class="error--text">Unpaid</span>
                    <span v-else class="success--text">Paid</span>
                </v-list-item-action>
            </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Supplier-Invoices-Blade',
    props: {
        bladesData: null
    }
}
</script>